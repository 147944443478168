<template>
    <div>
        <div class="record">激活记录</div>
        <div class="con">
            — 暂无记录 —
        </div>
    </div>
</template>

<script>
name: 'becomeVip2';
export default {

}
</script>

<style lang="scss" scoped>
.record {
    width: 200px;
    height: 30px;
    border-left: 5px solid #30C9B2;
    padding-left: 10px;
    line-height: 30px;
    font-size: 20px;
    margin-left: 3%;
}

.con {
    width: 100%;
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 15px;
    color: #666666;

}
</style>